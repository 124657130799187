import router from './router'
import store from "./store";
import NProgress from 'nprogress'
import "nprogress/nprogress.css"
import {Message, MessageBox} from "element-ui";

const whiteList = ['/','/login','/about','/pricing','/publications','/ts','/tutorials','/contact','/privacy-policy','/startNow','/register','/generateExternalReport','/viewReport','/reportInputForm','/changelog','/account','/resetPassword','/public/version']
router.beforeEach((to, from, next) => {
    NProgress.start()
    let token = sessionStorage.getItem('token');
    if (token && token.length > 20) {
        if (to.path === '/login' || to.path === '/register') {
            //if choose login , remove token, same logout
            next()
            // next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('isLogin');
            sessionStorage.removeItem('authorities');
            Message({
                message: 'current user does not have sufficient privilege to perform this action',
                type: 'error',
                duration: 5 * 1000
            })
            next({
                path: '/',
                query: {redirect: to.fullPath}
            })
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done() // End Progress
})
