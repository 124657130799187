<template>
  <div :style="{backgroundColor: bgColor || '#fff'}" class="common-layout">
    <div class="container">
      <headerLayout :menuIndex="menuIndex"></headerLayout>
      <div class="content" ref="content">
        <slot></slot>
      </div>
      <footer-layout></footer-layout>
    </div>
  </div>
</template>

<script>
import headerLayout from './headerNav'
import footerLayout from './footerLayout'

export default {
  name: "commonLayout",
  components: {
    headerLayout,
    footerLayout
  },
  props: {
    bgColor: {
      type: String,
      default: () => { return '' }
    },
    menuIndex: {
      type: Number,
      default: () => { return 0 }
    },
  }

}
</script>
<style lang="scss" scoped>
.common-layout{
  .container{
    min-height: 73vh;
  }
}

.footer {
  position: relative;
  margin-top: 0;
}
</style>