import axios from "axios";
import router from './../router'
import store from "../store";
import {Message, MessageBox} from "element-ui";

// Create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,  // api base_url
    timeout: 100000                 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {

    let loginUrl = '/authenticate'
    let registerUrl = '/register'

    if (config.url == loginUrl) {
        config.headers['Content-Type'] = 'application/json';
    }

    if (config.url == registerUrl) {
        return config
    }

    if (config.url != loginUrl && config.url != registerUrl || config.url != '/api/v2/reports/external' || config.url != '/api/v2/resources/fields' || !config.url.includes('/api/v2/resources/changelog')) {
        let token = sessionStorage.getItem('token')
        if ( token != null && token != '') {
            let credentials = 'Bearer ' + token
            config.headers.common['Authorization'] = credentials
        }
    }

    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
})

// response interceptor
service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if(error.response.config.url !=='/logout'){
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        Message({
                            message: 'Invalid username or password',
                            type: 'error',
                            duration: 5 * 1000
                        })
                        sessionStorage.removeItem('username');
                        sessionStorage.removeItem('user');
                        sessionStorage.removeItem('isLogin');
                        sessionStorage.removeItem('authorities');
                        router.replace({
                            path: '/',
                            query: {redirect: '/'}
                            // path: router.currentRoute.fullPath, // Return to the current URL
                            // query: {redirect: router.currentRoute.fullPath}
                        });
                        break;
                    case 403:
                        Message({
                            message: 'current user does not have sufficient privilege to perform this action',
                            type: 'error',
                            duration: 5 * 1000
                        })
                        break;
                    case 422:
                        let errorData = JSON.stringify(error.response.data);
                        if (error.response.config.url !== '/api/v2/reports/batch') {
                            Message({
                                message: `input file contains invalid parameter(s): ${errorData}`,
                                type: 'error',
                                duration: 5 * 1000
                            })
                        }
                        break;
                }
            }
        }else {
            Message({
                message: 'You have logout successfully!',
                type: 'info',
                duration: 5 * 1000
            })
            router.replace({
                path: '/login',
                query: {redirect: router.currentRoute.fullPath}
            });
        }
        return Promise.reject(error.response.data);
    }
)
export default service
