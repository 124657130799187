<template>
  <div class="login-container">
    <img src="@/assets/icon-blue-left-arrow.svg" class="left-arrow" @click="backToMenu"/>
    <span>Log in to your Sequence2Script Pro account</span>
    <el-form :hide-required-asterisk="true" ref="userInfo" :model="userInfo" :rules="rules" @keyup.enter.native="login">
      <el-form-item prop="username">
        <div class="login-input-wrapper">
          <input v-model="userInfo.username" placeholder="Username" class="login-input"/>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <div class="login-input-wrapper">
          <input v-model="userInfo.password" type="password" placeholder="Password" class="login-input"/>
        </div>
      </el-form-item>
    </el-form>

    <!-- Desktop and tablet login button -->
    <div class="bottom-section">
      <div class="forget-password">
        <span>Forgot your password?</span>
        <hr>
      </div>
      <div class="login-button-container">
        <button class="login-btn" @click="login">Login</button>
      </div>
    </div>

    <!-- mobile login button -->
    <div class="bottom-mobile-section">
      <button class="login-btn" @click="login">Login</button>
      <div class="forget-password">
        <span>Forgot your password?</span>
        <hr>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      userInfo: {
        username: null,
        password: null
      },
      rules: {
        username: [
          {required:true, message: 'Please enter the user name', trigger: ['blur', 'change']}
        ],
        password: [
          {required:true, message: 'Please enter your password..', trigger: ['blur', 'change']}
        ]
      }
    }
  },
  created() {
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('user')
    this.$store.dispatch('setUserInfo', null)
  },
  methods:{
    login() {
      this.$refs.userInfo.validate(val => {
        if (val) {
          this.$store.dispatch('Login', this.userInfo).then(user => {
            sessionStorage.setItem('username', this.userInfo.username)
            sessionStorage.setItem('user', this.userInfo.username)
            sessionStorage.setItem('isLogin', "true")
            this.$store.dispatch('setUserInfo', this.userInfo.username)
            this.$router.push('/internalReportForm')
            location.reload();
          }).catch(_ => {
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('user')
            sessionStorage.removeItem('isLogin')
            sessionStorage.removeItem('token')
            // location.reload();
          })
        }
      })
    },
    backToMenu() {
      // Emit a custom event that the parent component can listen for
      this.$emit('backToMenu');
    }
  }
}
</script>

<style lang="scss" scoped>

@media screen and (min-width: 1025px) {
  .left-arrow {
    display: none;
  }
  .bottom-mobile-section{
    display: none;
  }
  .bottom-section {
    display: block;
  }
}

@media screen and (max-width: 1024px) and (min-width: 421px) {
  .login-container {
    width: 446px !important;
    height: 434px !important;
    left: 100% !important;
    transform: translateX(-100%) !important;
  }
  .left-arrow {
    display: block !important;
    margin-top: 40px;
    margin-left: 60px;
    align-self: flex-start;
  }
  .bottom-mobile-section{
    display: none;
  }
  .bottom-section {
    display: block;
  }
}

@media screen and (max-width: 420px) {
  .login-container {
    width: 280px !important;
    height: 522px !important;
    left: 100% !important;
    transform: translateX(-100%) !important;
  }

  .left-arrow {
    display: block !important;
    margin-top: 40px;
    margin-left: 23px;
    align-self: flex-start;
  }

  .login-container span {
    margin-top: 30px !important;
    width: 220px !important;
  }

  .login-input-wrapper {
    width: 220px !important;
    margin-top: 20px !important;
  }

  .bottom-section {
    display: none !important;
  }

  .bottom-mobile-section{
    display: block !important;
  }

  .login-btn {
    width: 220px !important;
    margin-left: 20px !important;
  }

  .forget-password {
    margin-left: 52px !important;
  }

}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 475px;
  height: 372px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  left: 50%;
  transform: translateX(-50%);
}

.login-container span {
  margin-top: 40px;
  width: 355px;
  height: 52px;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #224A4C;
  text-align: left;
}

.el-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.el-form-item:last-child {
  margin-bottom: 20px;
}

.login-input-wrapper {
  width: 355px;
  height: 56px;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-input {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 120px); /* Subtracting the left and right spacing */
  margin-left: 60px;
  margin-right: 60px;
}

.forget-password{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -20px;
  span{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #224A4C;
    text-align: left;
  }

  hr{
    width: 135px;
    border: 3px solid #224A4C;
    margin-top: -25px;
  }
}

.login-btn {
  min-width: 115px;
  height: 48px;
  display: flex;
  padding: 20px 45px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
  outline: none;
  background-color: #F9B300;
  border: none;
  color: #224A4C;
  margin-left: -120px;
  font-family: Ubuntu;
  font-style: normal;
  line-height: normal;
}
</style>