import   jwt  from   'jsonwebtoken'
import {setToken, removeToken} from "@/utils/auth";
import  { login } from '@/api/login'
import axios from 'axios'
import store from "../index";
const user = {
    state: {
        token: null,
        authorities: JSON.parse(sessionStorage.getItem('authorities')) || null,
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            sessionStorage.setItem('token', token)
        },
        SET_AUTHORITIES: (state, authorities) => {
            state.authorities = authorities
            sessionStorage.setItem('authorities', JSON.stringify(authorities))
        },
        REMOVE_TOKEN: (state) => {
            state.token = null
        },
        REMOVE_AUTHORITIES: (state) => {
            state.authorities = null
            sessionStorage.removeItem('authorities')
        }
    },

    actions: {
        Login({ commit }, userInfo) {
            // userInfo.username = userInfo.username.trim()
            return new Promise((resolve, reject) => {
                login(userInfo.username, userInfo.password).then(response => {
                    const token = response.data.token
                    let jwtObj = jwt.decode(token)
                    console.log(jwtObj)
                    if(jwtObj != null && jwtObj.authorities != 'undefined'){
                        commit('SET_AUTHORITIES', jwtObj.authorities)
                    }
                    removeToken()
                    setToken(token)
                    commit('SET_TOKEN', token)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default user