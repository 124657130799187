<template>
  <div class="profile-container">
    <ul>
      <li v-if="hasImport" >
        <a href="#/importData">
          <span>Admin Tasks - Import Data</span>
        </a>
      </li>
      <li>
        <a href="#/resetPassword">
          <span>My account</span>
        </a>
      </li>
      <li>
        <a href="#/batchReportStatus">
          <span>Batch reports</span>
        </a>
      </li>
      <li>
        <a href="#/" @click.prevent="handleLogout">
          <hr>
          <span class="logout-item">Log out</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { logout } from '@/api/login'
import {removeToken} from "@/utils/auth";
export default {
  name: 'userProfile',
  props: {
    hasImport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userInfo: {
        username: null,
      }
    }
  },
  methods:{
    handleLogout() {
      logout().then(response => {
        if (response.status === 401) {
          console.log('Unauthorized access. Please login again.');
        }
        this.clearSessionAndRedirect();
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          console.log('Unauthorized access. Please login again.');
        }
        this.clearSessionAndRedirect();
        location.reload();
      });
    },

    clearSessionAndRedirect() {
      removeToken();
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('isLogin');
      sessionStorage.removeItem('authorities');
      this.$store.dispatch('setUserInfo', null);
      this.$router.push('/');
    },
  }
}
</script>

<style lang="scss" scoped>

.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 315px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  left: 48.4%;
  //transform: translateX(-50%);
}

.profile-container ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
}

.profile-container li {
  list-style: none;
  margin: 16px;
  text-align: left;
}

.profile-container li.line {
  border-bottom: none; // This will remove the line under the last list item
}

hr{
  width: 100%;
  border: 1px solid #DFDFDF;
  margin-top: -10px;
}

.profile-container span {
  margin-top: 40px;
  width: 355px;
  height: 52px;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #224A4C;
  text-align: left;
}

.profile-container .logout-item {
  color: #BB3E3E;
}
</style>