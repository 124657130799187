<template>
  <div class="footer-container">

    <el-row class="footer-row">

      <el-col>
        <div class="footer-links" >
          <ul style="text-align: left;">
            <img class="footer-logo" alt="logo" src="./../assets/logo_white_halftone_alt.svg"/>
          </ul>
          <ul style="text-align: left;">
            <i>
              <a href="https://ca.linkedin.com/company/sequence2script-inc">
                <img src="./../assets/fa_brands_linkedin.svg"/>
              </a>
            </i>
          </ul>
        </div>
      </el-col>

      <el-col class="col-navigation" >
        <div class="footer-navigation">
          <ul>
            <li>Navigation</li>
            <li :class="{
                'active-menu': index === active
              }" @click="menu(list, index)" v-for="(list,index) in menuList" :key="index">{{list.label}}</li>
          </ul>
        </div>
      </el-col>

      <el-col >
        <div class="footer-address">
          <ul>
            <li>Sequence2Script Inc.</li>
            <li>Life Sciences Innovation Hub</li>
            <li>3655 36 St NW</li>
            <li>Calgary, AB  T2L 1Y8</li>
            <li>info@sequence2script.com</li>
          </ul>
        </div>
      </el-col>
    </el-row>

    <el-row class="footer-box">
      <el-col class="footer-box-content">
        <span>© 2023 Sequence2Script Inc. All rights reserved.</span>
        <div class="policy-terms">
          <router-link to="/privacy-policy" tag="span" class="clickable">Privacy policy</router-link>
          <router-link to="/ts" tag="span" class="clickable">Terms and conditions</router-link>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "footerLayout",
  data() {
    return {
      active: 0,
      menuList: [{
        label: 'Home',
        value: '/'
      }, {
        label: 'About',
        value: '/about'
        // }, {
        //   label: 'Pricing',
        //   value: '/pricing'
      }, {
        label: 'Request a demo',
        value: '/contact'
      }]
    }
  },
  methods: {
    menu(data, index) {
      this.active = index
      this.$router.push(data.value)
    }
  }
}
</script>

<style lang="scss" scoped>

.clickable {
  cursor: pointer;
}

.footer-container {
  position: relative;
  padding:40px 0;
  color: #fff;
  min-height: 17vh;
  font-weight: bold;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(31, 124, 139);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .footer-logo {
    width: 192px;
    height: 29px;
    display: flex;
    padding: 0 20px 5px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .footer-row {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .footer-box {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: left;
    border-top: 1px solid #DFDFDF;
    padding: 10px 0;
  }

  .footer-box-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .footer-box-content span {
    display: inline-block;
  }

  .footer-box span {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 135%;
    color: #FFFFFF;
  }

  .policy-terms span {
    margin-left: 10px;
  }

  .el-row {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }

  .el-row > .el-col:first-child {
    margin-left: 0;
  }

  .el-row > .el-col:last-child {
    margin-right: 0;
  }

  i {
    font-size: 40px;
    &:first-child {
      margin-right: 50px;
    }
  }

  > div {
    width: 100%;
    padding:20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    h2 {
      margin-top: 0;
    }

    ul {
      min-width: 160px;
      text-align: left;

      li {
        cursor: pointer;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        &:first-child {
          font-weight: bold;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .active-menu {
        /*color: rgb(247,177,42);*/
      }
    }

    .footer-links{
      padding-left: 2px;
      ul {
        width: 243.67px;
      }
    }

    .footer-navigation{
      ul {
        max-width: 260px;
      }
    }

    .footer-address {
      padding-left:120px;
      ul {
        width: 243.67px;
        li:last-child {
          font-weight: bold;
          color: #f9b300;
          margin-top: 20px;
        }
      }
    }
  }

  @media (max-width: 1200px) and (min-width: 1025px) {
    .footer-row {

    }
  }

  @media (max-width: 1024px) {
    .footer-links{
      padding-left: 60px !important;
    }

    .footer-address {
      padding-left: 0 !important;
    }

    .footer-box {
      width: 90% !important;
    }

  }

  @media (max-width: 744px) {
    flex-direction: column;
    justify-content: space-evenly;
    height: auto;

    > div {
      flex-direction: column;
      align-items: center;

      .footer-links,
      .footer-navigation,
      .footer-address {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }

      .col-navigation {
        margin-left: -11% ;
      }
    }

    .footer-links{
      padding-left: 0 !important;
    }
  }

  @media (max-width: 421px) {
    .footer-container {
      margin-left: 0;
    }

    .el-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .footer-box-content {
      flex-direction: column;
      align-items: center;
    }

    .footer-box-content span {
      display: block;
      text-align: center;
      margin-bottom: 5px;  /* 添加一些间距 */
    }

    .policy-terms {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .policy-terms span {
      margin-left: 0;
    }
  }

}
</style>