import Vue from 'vue'
import Router from 'vue-router'
const _import = require('./_import_' + process.env.NODE_ENV)
Vue.use(Router)
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export const constantRouterMap = [
    {
        path: '/',
        name: 'Home',
        component: _import('home/index'),
        meta: {
            title: "Home - Sequence2Script"
        }
    }, {
        path: '/about',
        name: 'About',
        component: _import('about/index'),
        meta: {
            title: "About - Sequence2Script"
        }
    },  {
        path: '/pricing',
        name: 'Pricing',
        component: _import('pricing/index'),
        meta: {
            title: "Pricing - Sequence2Script"
        }
    }, {
        path: '/tutorials',
        name: 'Tutorials',
        component: _import('faqs/index'),
        meta: {
            title: "Tutorials - Sequence2Script"
        }
    },  {
        path: '/publications',
        name: 'Publications',
        component: _import('publications/index'),
        meta: {
            title: "Publications - Sequence2Script"
        }
    }, {
        path: '/contact',
        name: 'Contact',
        component: _import('contact/index'),
        meta: {
            title: "Contact - Sequence2Script"
        }
    }, {
        path: '/login',
        name: 'Login',
        component: _import('login/index'),
        meta: {
            title: "Login - Sequence2Script"
        }
    }, {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: _import('privacy/index'),
        meta: {
            title: "Privacy Policy - Sequence2Script"
        }
    },{
        path: '/ts',
        name: 'ts',
        component: _import('ts/index'),
        meta: {
            title: "Terms & Conditions - Sequence2Script"
        }
    }, {
        path: '/viewReport',
        name: 'viewReport',
        component: _import('report/index'),
        meta: {
            title: "View Report - Sequence2Script"
        }
    },{
        path: '/internalReportPrintPage',
        name: 'internalReportPrintPage',
        component: _import('report/index'),
        meta: {
            title: "View Report - Sequence2Script"
        }
    },{
        path: '/importData',
        name: 'importData',
        component: _import('home/importData'),
        meta: {
            title: "Import Data - Sequence2Script"
        }
    }, {
        path: '/batchReport',
        name: 'batchReport',
        component: _import('home/batchReport'),
        meta: {
            title: "Batch Reports - Sequence2Script"
        }
    }, {
        path: '/batchReportStatus',
        name: 'batchReportStatus',
        component: _import('home/batchReportStatus'),
        meta: {
            title: "Batch Status - Sequence2Script"
        }
    },  {
        path: '/reportInputForm',
        name: 'ReportForm',
        component: _import('home/reportInputForm'),
        meta: {
            title: "Create Report - Sequence2Script",
            keepAlive: true
        }
    },{
        path: '/changelog',
        name: 'changelog',
        component: _import('changelog/index'),
        meta: {
            title: "Changelog",
            keepAlive: true
        }
    }, {
        path: '/internalReportForm',
        name: 'internalReportForm',
        component: _import('home/reportInputForm'),
        meta: {
            title: "Create Report - Sequence2Script",
            keepAlive: true
        }
    }, {
        path: '/account',
        name: 'account',
        component: _import('account/index'),
        meta: {
            title: "My Account - Sequence2Script",
        }
    },{
        path: '/resetPassword',
        name: 'resetPassword',
        component: _import('account/resetPassword'),
        meta: {
            title: "Reset Password",
        }
    }
]

const router = new Router({
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRouterMap
})
export default router

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export const asyncRouterMap = []
