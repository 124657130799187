<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>

<script>

export default {
  name: 'App',        
  mounted() {
      console.info('App version ' + this.$store.getters.appVersion)
  }
}
</script>

<style>
  @import "./styles/index.scss";
#app {
  font-family: Source Sans Pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

  @import url("https://fonts.googleapis.com/css?family=Lato:400,700");
  @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
  @import url("https://fonts.googleapis.com/css?family=Ubuntu");
</style>
