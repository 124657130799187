import Cookies from 'js-cookie'
const app = {
    state: {
        user: null,
        sliderOpen: true,
        loading: false,
        packageVersion: process.env.PACKAGE_VERSION || 0
    },
    mutations: {
        TOGGLE_SIDEBAR: state => {
            state.sliderOpen = !state.sliderOpen
            Cookies.set('sidebarStatus', Number(state.sliderOpen))
        },
        SET_LOADING: (state,data) => {
            state.loading = data
        },
        SET_USER: (state,data) => {
            state.user = data
        }
    },
    actions: {
        toggleSideBar: ({ commit }) => {
            commit('TOGGLE_SIDEBAR')
        },
        setLoadingStatus: ({commit}, data) => {
            commit('SET_LOADING', data)
        },
        setUserInfo: ({commit}, data) => {
            commit('SET_USER', data)
        }
    }
}

export default app