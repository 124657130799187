<template>
  <div class="header-container" :style="{ display: hideNavbar ? 'none' : 'block' }">
    <div class="menubar" @touchmove.prevent v-show="!showSideMenu">
      <div class="menubar-bg" :class="showSideMenu"></div>
      <!--logo-->
      <logo></logo>
      <ul>
        <div class="left-items">
        <img src="@/assets/icon_cross.svg" class="close-cross" @click="handleCloseModal"/>
        <li class="home-lg"><a href="#/">
          <span class="nav-item" :class="index===1 ">Home</span>
        </a></li>
        <li><a href="#/about">
          <span class="nav-item" :class="index===2 ">About</span>
        </a></li>
<!--        <li>-->
<!--          <a href="#/pricing">-->
<!--          <span class="nav-item" :class="index===3 ">Pricing</span>-->
<!--          </a>-->
<!--        </li>-->

        <li><a href="#/contact">
          <span class="nav-item" :class="index===4 ">Request a demo</span>
        </a></li>
        </div>

        <div class="right-items">
          <li @click="handleProfile" v-if="isLogin">
            <a>
              <span class="nav-item" :class="index===5 && 'nav-active'">Hi, {{ userInfo.username }}!</span>
            </a>
          </li>

          <li class="login" @click="handleLoginClick" v-else>
            <a>
              <span class="nav-item" :class="index===5 ">Login</span>
              <img src="@/assets/icon-right-arrow.svg" class="login-arrow"/>
            </a>
          </li>

          <li class="try-free">
            <a>
              <span class="nav-item" :class="index===6" @click="handleTryFreeClick" v-if="!isLogin">
                Try it now!
              </span>
              <span class="nav-item" :class="index===6" @click="handleTryFreeClick" v-if="isLogin">
                Start Now
              </span>
          </a>
          </li>
        </div>
      </ul>

      <div id="toggleBar" class="bar" @click="toggleSliderMenu">
        <img src="@/assets/icon-nav.svg" alt="" class="nav-logo">
      </div>
    </div>
    <login
        class="login-win win-zIndex"
        ref="Login"
        v-if="showModalType === 'Login'"
        @closeModal="handleCloseModal"
        @removeEventPageClick="removeEventPageClick"
        @addEventPageClick="addEventPageClick"
        @backToMenu="handleBackToMenu"
    />
    <userProfile
        class="profile-win win-zIndex"
        ref="UserProfile"
        v-if="showModalType === 'UserProfile' && isLogin"
        :hasImport=hasImport
        @closeModal="handleCloseModal"
        @removeEventPageClick="removeEventPageClick"
        @addEventPageClick="addEventPageClick"
    />
  </div>
</template>
<script>
import logo from '@/components/logo'
import login from '@/components/login';
import userProfile from '@/components/userProfile';

export default {
  name: "headerLayout",
  components: {
    logo,
    login,
    userProfile
  },
  data(){
    return{
      showSideMenu:false,
      hideNavbar:false,
      showModalType: '', // Control the display type of the modal
      userInfo: {
        username: null
      },
      isLogin: false,
      hasImport:false
    }
  },
  computed:{
    index(){
      // Activate the .nav-active property in css via vue-router
      // Define your routes in advance based on your own needs
      let path = this.$route.name;
      switch(path){
        case 'Home':
          return 1;
        case 'About':
          return 2;
        case 'Pricing':
          return 3;
        case 'Contact':
          return 4;
        case 'Login':
          return 5;
        case 'ReportForm':
          return 6;
        case 'blank':
          return 7;
      }
    }
  },
  mounted(){
    let that = this;
    // let toggleBar = document.getElementById('toggleBar');
    let MenubarUl = document.querySelector('.menubar ul');
    let RightSliderMenubar  = document.querySelector('.menubar ul');

    //hide the nav when click the nav
    MenubarUl.onclick = function() {
      RightSliderMenubar .classList.remove('active');
      document.body.style.overflow = 'scroll';
    }

    //top nav when scroll
    window.onscroll = function() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop===0){
        that.showSideMenu = false;
        that.hideNavbar = false;
      }else{
        that.showSideMenu = true;
        //hide the top navbar when scroll
        that.hideNavbar = true;
      }
    }
    // Get the username from sessionStorage
    let username = sessionStorage.getItem('username');
    if (username) {
      this.userInfo.username = username
    }else {
      this.userInfo.username = null
    }
    if(sessionStorage.getItem('isLogin') && sessionStorage.getItem('isLogin') === 'true') {
      this.isLogin = true
    }else {
      this.isLogin = false
    }
    this.adminImport();
  },
  methods:{
    //show the slider nav when click the toggleSliderMenu
    toggleSliderMenu() {
      const RightSliderMenubar = document.querySelector('.menubar ul');
      RightSliderMenubar.classList.toggle('active');

      if (RightSliderMenubar.classList.contains('active')) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'scroll';
      }
    },
    handleLoginClick() {
      this.isShowModal('Login')
    },
    handleProfile() {
      const itemPosition = event.target.getBoundingClientRect();
      if(this.isLogin) {
        this.isShowModal('UserProfile')
      }
    },
    handleTryFreeClick() {
      this.$router.push({path: '/reportInputForm'})
      // if(this.isLogin) {
      //   this.$router.push({path: '/internalReportForm'})
      // }else {
      //   this.$router.push({path: '/reportInputForm'})
      // }
    },
    //control the pop window show or hide
    isShowModal(type) {
      // Remove the page listening event
      this.removeEventPageClick()
      if (this.showModalType === type) {
        this.showModalType = ''
      } else {
        this.showModalType = type
      }
      //Trigger the listener
      this.addEventPageClick()
    },
    // Trigger the page listener
    addEventPageClick() {
      setTimeout(() => {
        document.addEventListener('click', this.bodyCloseModal)
      }, 100)
    },
    //remove the page listener
    removeEventPageClick() {
      document.removeEventListener('click', this.bodyCloseModal)
    },
    //Close when clicking outside area
    bodyCloseModal(e) {
      let self = this
      if (
          this.showModalType &&
          this.$refs[this.showModalType] &&
          !this.$refs[this.showModalType].$el.contains(e.target)
      ) {
        if (self.showModalType) {
          self.showModalType = ''
          self.removeEventPageClick()
        }
      }
    },
    //Actively close
    handleCloseModal() {
      this.showModalType = ''
    },
    handleBackToMenu() {
      this.showModalType = ''
      // If you are controlling the visibility of the right side menu via some data property, toggle it here
      let RightSliderMenubar  = document.querySelector('.menubar ul');
      RightSliderMenubar .classList.toggle('active');
      document.body.style.overflow = 'hidden';
    },
    adminImport() {
      this.hasImport = false;
      if (this.$store.getters.authorities != null ){
        this.$store.getters.authorities.forEach( obj =>{
          if(obj.authority === 'ROLE_ADMIN'){
            this.hasImport = true
          }
        })
      }
      return this.hasImport
    }
  }

}
</script>
<style lang="scss" scoped>

.nav-active{
  //color: white !important;
  font-weight: bold !important;
  border-bottom: 2px solid white !important;
  box-sizing: border-box !important;
  transition: none !important;
  //background-color: #F9B300;
  //box-shadow: 0 0 6px #0d0d0dab;
}
.nav-logo{
  display: none;
  width: 30px;
  margin-top: 15px;
  vertical-align: middle;
  margin-left: 15px;
}
.xd-active{
  opacity: 1 !important;
  box-shadow: 0 0 6px #0d0d0dab;
}

.win-zIndex {
  z-index: 2000;
}

.login-win {
  position: absolute;
  top: 61px;
}

.profile-win {
  position: absolute;
  top: 61px;
}

.header-container{
  position: fixed;
  left: 0;
  top: 0;
  height: 61px;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(48, 48, 48, 0.15)
}

.menubar {
  max-width: 1200px;
  margin: auto;
  padding: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  user-select: none;
  align-items: center;
  justify-content: space-between;
}

.menubar-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  z-index: -1;
  background: #F9B300;
  opacity: 0;
}

.menubar .logo {
  min-width: 120px;
  width: 10%;
  text-align: center;
}

.menubar .login{
  border-top: 1px solid lightgrey;
}

.menubar .login .login-arrow {
  margin-left: 200px;
}

.menubar .try-free{
  background-color: #F9B300;
}

.menubar .user {
  width: 20%;
  min-width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 30px;
  align-items: center;
  cursor: pointer;
}

.menubar .user .username {
  font-size: 14px;
  color: #eee;
  height: 60px;
  line-height: 60px;
}

.nav-item {
  padding: 18.5px 30px;
  position: relative;
  display: block;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #224A4C;
  text-align: center;
}

.close-cross {
  margin-top: 30px;
  margin-left: 23px;
  align-self: flex-start;
}

@media (min-width: 1025px) {
  .home-lg, .close-cross, .login-arrow {
    display: none;
  }
  .blank-item{
    display: block;
  }

  .left-items, .right-items {
    display: flex;
  }

  .left-items > li {
    margin-right: auto;
  }

  .right-items > li {
    margin-left: auto;
  }

  .menubar ul {
    position: relative;
    width: 100%;
    max-height: 500px;
    display: flex;
    flex-direction: row;
    //justify-content: left;
    justify-content: space-between;
    text-align: center;
  }

  .menubar ul li {
    min-width: 50px;
    list-style: none;
    margin-left: 20px;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .menubar ul li a {
    position: relative;
    display: block;
    width: 100%;
    line-height: 60px;
    text-decoration: none;
  }
  .menubar ul li a:hover span{
    transition: all 0.3s ease;
    color: #FFFFFF;
  }

  .menubar ul li a:hover .nav-item {
    background-color: #224A4C;
    padding: 18.5px 30px;
    transition: background-color 0.3s ease, padding 0.3s ease;
  }

}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
  .menubar {
    margin-left: 60px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 421px) {
  .nav-logo {
    display:block;
  }
  .home-lg {
    display: block;
  }
  .blank-item {
    display: none;
  }

  .nav-active{
    color: #F9B300 !important;
    font-weight: bold !important;
    border-bottom: none !important;
    box-sizing: border-box !important;
    transition: all 0.1s ease-in;
  }

  .menubar {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    height: 64px;
    transition: .321s;
  }

  .menubar .logo {
    position: absolute;
    width: 100%;
    top: 0;
    text-align: center;
  }

  .menubar ul {
    right: 0px;
    width: 0px;
    position: fixed;
    height: 560px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow: hidden;
    transition: .141s;
    z-index: 10;
  }

  .menubar ul.active {
    right: 0;
    width: 446px;
    transition: .314s;
  }

  .menubar ul.active::after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  .menubar ul li {
    width: 100%;
  }

  .menubar ul li a {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .menubar ul li a .nav-item {
    margin-top: 10px;
    margin-left: 60px;
    position: relative;
    display: block;
    height: 37px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    font-size: 18px;
  }

  .menubar .bar {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
  }

  .menubar .user .username {
    display: none;
  }

}

@media (max-width:480px) {
  .nav-logo{
    display:block;
  }
  .home-lg {
    display: block;
  }
  .blank-item{
    display:none;
  }

  .menubar .login .login-arrow {
    margin-left: 80px !important;
  }

  .nav-active{
    color: #F9B300 !important;
    font-weight: bold !important;
    border-bottom: none !important;
    box-sizing: border-box !important;
    transition: all 0.1s ease-in;
  }

  .menubar {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    height: 54px;
    transition: .321s;
  }

  .menubar .logo {
    position: absolute;
    width: 100%;
    top: 0;
    text-align: center;
  }

  .menubar ul {
    right: 0px;
    width: 0px;
    position: fixed;
    height: 552px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow: hidden;
    transition: .141s;
    z-index: 10;
  }

  .menubar ul.active {
    right: 0;
    width: 280px;
    transition: .314s;
  }

  .menubar ul.active::after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  .menubar ul li {
    width: 100%;
  }

  .menubar ul li a {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .menubar ul li a .nav-item {
    margin-top: 10px;
    margin-left: 60px;
    position: relative;
    display: block;
    height: 37px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
  }

  .menubar .bar {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }

  .menubar .user .username {
    display: none;
  }

}

</style>
