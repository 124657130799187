import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './langs/2052'
import en from './langs/1033'
import {getCookie} from '@/utils/auth'
Vue.use(VueI18n)
const messages = {
    en: en,
    zh: zh
}
const lcid = Number(getCookie('lcid'))
let lang = null
switch (lcid) {
    case 2052:
        lang ='zh'
        break;
    case 1033:
        lang = 'en'
        break;
    default:
        lang = 'en'
        break;
}
const i18n = new VueI18n({
    locale: lang,
    messages
})

export default i18n