import fetch from '@/utils/fetch'

export function login(username, password) {
    return fetch({
        url: '/authenticate',
        method: 'post',
        data: {
            'username': username,
            'password' : password
        }
    })
}

export function logout() {
    return fetch({
        url: '/logout',
        method: 'post'
    })
}