import Vue from 'vue'
import App from './App.vue'
const router = require('./router').default
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
import i18n from './i18n/index'
import 'normalize.css/normalize.css'
import '@/permission'
import  VueQuillEditor from 'vue-quill-editor'
import glcomponents from '@/utils/globalComponents'
import commonMethods from '@/utils/commonMethods'
import bus from '@/utils/eventBus'
import Print from 'vue-print-nb'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.config.productionTip = false
Vue.prototype.$commonMethods = commonMethods
import locale from 'element-ui/lib/locale/lang/en'
Vue.prototype.$bus = bus
Vue.use(ElementUI, { locale })
Vue.use(VueQuillEditor)
Vue.use(glcomponents)
Vue.use(Print)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
