<template>
    <div>
        <el-popover
                placement="bottom-start"
                :width="width ? width : 270"
                trigger="click">
            <div class="option-list">
                <div v-if="all" class="select-all">
                    <el-checkbox @change="changeAll" v-model="allSelect"><span>Select All</span></el-checkbox>
                </div>
                <div v-for="(item,index) in listOptions" :key="index">
                    <el-checkbox-group v-model="item.checked" fill="#157c8c" @change="changeStatus" text-colo="#157c8c">
                        <el-checkbox><span v-html="item.label"></span></el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="select-style" slot="reference">
                <span>{{placeholder}}</span>
                <i class="el-icon-arrow-down"></i>
            </div>
            <div></div>
        </el-popover>
    </div>
</template>

<script>
    export default {
        name: "dropdownSelect",
        props: {
            width: null,
            placeholder: {
                type: String,
                default: () => { return 'Filter' }
            },
            all: false,
            options: {
                type: Array,
                default: () => { return [] }
            }
        },
        data() {
            return {
                show: true,
                allSelect: true
            }
        },
        watch: {
            listOptions: {
                deep: true,
                handler(newVal, oldVal) {
                    let isAll = true
                    newVal.forEach(item => {
                        if (!item.checked) {
                            isAll = false
                        }
                    })
                    this.allSelect = isAll
                }
            }
        },
        computed: {
            listOptions() {
                return JSON.parse(JSON.stringify(this.options))
            }
        },
        methods: {
            changeAll() {
                this.listOptions.forEach(item => {
                    item.checked = this.allSelect
                })
                this.$emit('newData', this.listOptions)
            },
            changeStatus() {
                this.$forceUpdate()
                this.$emit('newData', this.listOptions)
            }
        }
    }
</script>

<style lang="scss">
    .el-popover, .el-radio-button:first-child:last-child .el-radio-button__inner{
        border-radius: none;
    }
    .option-list{
        font-family: Source Sans Pro;
        .el-checkbox{
            margin-bottom: 5px;
            display: flex;
            align-items: flex-start;
            .el-checkbox__inner{
                margin-top: 2px;
                border-radius: 0;
                width: 19px;
                height: 19px;
                border-color: #303030;
            }
            .el-checkbox__inner::after{
                height: 8px;
                left: 7px;
                top: 2px;
                border-right-width:2px;
                border-bottom-width:2px;
            }
        }
        .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #157c8c;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
            color: #157c8c;
            border-color: #303030;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
            background-color: #157c8c;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner{
            border-color: #303030;
        }
        .el-checkbox__inner:hover{
            border-color: #303030;
        }
        .select-all{
            .el-checkbox__input.is-checked+.el-checkbox__label{
                color: #000;
            }
            .el-checkbox{
                color: #000;
            }
            /*.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{*/
                /*color: #000;*/
                /*border-color: #000;*/
            /*}*/
            /*.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{*/
                /*background-color: #000;*/
            /*}*/
            /*.el-checkbox__input.is-focus .el-checkbox__inner{*/
                /*border-color: #000;*/
            /*}*/
            /*.el-checkbox__inner:hover{*/
                /*border-color: #000;*/
            /*}*/
        }
    }
</style>
<style lang="scss" scoped>
    .select-style{
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #DCDFE6;
        border: 1px solid #DCDFE6;
        padding: 7.5px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
    }
    .option-list{
        max-height: 400px;
        overflow-y: auto;
        .select-all{
            border-bottom: 1px solid #000;
            margin-bottom: 5px;
            span{
                font-weight: bold;
            }
        }
    }
</style>