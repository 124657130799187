import { Message } from "element-ui";
// 返回某一个容器的宽度
const getPlateWidth = (name) => {
  let width = 0;
  let dom = null;
  if (name.slice(0, 1) === ".") {
    dom = document.getElementsByClassName(name.slice(1))[0];
  } else if (name.slice(0, 1) === "#") {
    dom = document.getElementById(name.slice(1));
  }
  if (dom) width = dom.offsetWidth;
  return width;
};

// 用于设置某一个容器的高度
/**
 *
 * @param classArr [{className:'', scale:''}]
 * @param num Number
 * @returns {boolean}
 */
export function setPlateHeight(classArr, num) {
  if (!arguments.length) {
    Message({
      message: "请在设置固定高的公用函数传入参数！",
      type: "error",
      center: true,
    });
    return false;
  } else if (typeof num != "number") {
    Message({
      message: "请在设置固定高的公用函数第二参数传入该页面空隙高度总和！",
      type: "error",
      center: true,
    });
    return false;
  }
  // const topHeight = document.getElementsByClassName('navbar')[0].offsetHeight + document.getElementsByClassName('menu-list')[0].offsetHeight
  const topHeight =
    document.getElementsByClassName("el-header")[0].offsetHeight + 40;
  const height = document.documentElement.clientHeight;
  let temHeight = 0;
  for (let key in arguments) {
    if (key != "0" && key != "1" && arguments.hasOwnProperty(key)) {
      if (arguments[key] instanceof Array) {
        for (let i = 0; i < arguments[key].length; i++) {
          let tempDom = document.getElementsByClassName(arguments[key][i])[0];
          if (tempDom) {
            temHeight += tempDom.offsetHeight;
          }
        }
      } else {
        let tempDom = document.getElementsByClassName(arguments[key])[0];
        if (tempDom) {
          temHeight += tempDom.offsetHeight;
        }
      }
    }
  }
  if (classArr instanceof Array) {
    let countHeight = height - topHeight - temHeight - num;
    classArr.map((item) => {
      let dom = document.getElementsByClassName(item.className)[0];
      if (dom) {
        dom.style.minHeight = countHeight * item.scale + "px";
      }
    });
  } else {
    let dom = document.getElementsByClassName(classArr)[0];
    if (dom) {
      dom.style.height = height - topHeight - temHeight - num + "px";
    }
  }
}

//获取设备类型（ipad,phone,pc）
export function getOs() {
  const ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc,
  };
}

export default {
  getPlateWidth,
  setPlateHeight,
  getOs,
};
